exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about_us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-application-security-tsx": () => import("./../../../src/pages/application_security.tsx" /* webpackChunkName: "component---src-pages-application-security-tsx" */),
  "component---src-pages-blockchain-security-tsx": () => import("./../../../src/pages/blockchain_security.tsx" /* webpackChunkName: "component---src-pages-blockchain-security-tsx" */),
  "component---src-pages-cloud-security-tsx": () => import("./../../../src/pages/cloud_security.tsx" /* webpackChunkName: "component---src-pages-cloud-security-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cyber-risk-management-tsx": () => import("./../../../src/pages/cyber_risk_management.tsx" /* webpackChunkName: "component---src-pages-cyber-risk-management-tsx" */),
  "component---src-pages-data-privacy-tsx": () => import("./../../../src/pages/data_privacy.tsx" /* webpackChunkName: "component---src-pages-data-privacy-tsx" */),
  "component---src-pages-details-tsx": () => import("./../../../src/pages/details.tsx" /* webpackChunkName: "component---src-pages-details-tsx" */),
  "component---src-pages-enterprise-security-tsx": () => import("./../../../src/pages/enterprise_security.tsx" /* webpackChunkName: "component---src-pages-enterprise-security-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industrial-security-tsx": () => import("./../../../src/pages/industrial_security.tsx" /* webpackChunkName: "component---src-pages-industrial-security-tsx" */),
  "component---src-pages-managed-services-tsx": () => import("./../../../src/pages/managed_services.tsx" /* webpackChunkName: "component---src-pages-managed-services-tsx" */),
  "component---src-pages-managed-vapt-tsx": () => import("./../../../src/pages/managed_vapt.tsx" /* webpackChunkName: "component---src-pages-managed-vapt-tsx" */),
  "component---src-pages-network-security-tsx": () => import("./../../../src/pages/network_security.tsx" /* webpackChunkName: "component---src-pages-network-security-tsx" */),
  "component---src-pages-threat-simulations-tsx": () => import("./../../../src/pages/threat_simulations.tsx" /* webpackChunkName: "component---src-pages-threat-simulations-tsx" */),
  "component---src-pages-why-us-tsx": () => import("./../../../src/pages/why_us.tsx" /* webpackChunkName: "component---src-pages-why-us-tsx" */)
}

