// This would go in gatsby-browser.js
import React from "react";
import { ThemeProvider } from "./src/context/ThemeContext";
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  if (!location.hash) {
    setTimeout(() => {
      window.scrollTo({
        top: currentPosition[0] || 0,
        left: currentPosition[1] || 0,
        behavior: "instant",
      });
    }, 0);
    return false;
  }
};

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
);