import React, { createContext, ReactNode, useContext, useEffect, useState, } from "react";

interface themeInterface {
    theme: string,
    toggleTheme: () => void;
}
const ThemeContext = createContext<themeInterface | undefined>(undefined)

export const ThemeProvider = ({ children }: Readonly<{ children: ReactNode }>) => {
    const [theme, setTheme] = useState("dark")
    const [isMounted, setIsMounted] = useState(false)
    useEffect(() => {
        if (!isMounted) {
            const defaultTheme = localStorage.getItem("theme")
            if (defaultTheme) {
                setTheme(defaultTheme)
            }
            setIsMounted(true)
        }
    }, [isMounted])
    useEffect(() => {
        const rootElement = document.documentElement;
        if (theme === "dark") {
            localStorage.setItem("theme", "dark")
            rootElement.classList.remove('light');
            rootElement.classList.add('dark');
        } else {
            rootElement.classList.remove('dark');
            rootElement.classList.add('light');
            localStorage.setItem("theme", "light")
        }
    }, [theme])
    const toggleTheme = () => {
        setTheme((prevTheme) => prevTheme === "dark" ? "light" : "dark")
    }
    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    )

}
export const useTheme = () => {
    const context = useContext(ThemeContext)
    if (context === undefined) {
        throw new Error("useAuth  must be used within a provider")
    }
    return context
}
